import {
  Checkbox,
  Col,
  Drawer,
  InputNumber,
  Popover,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import {
  InfoCircleOutlined,
  QuestionCircleOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import i18n from "../../../../i18n";
import { ILeverCell, ILeverQuestion } from "../../../../Models/Levers/Items";
import { ILeverSubquestion } from "../../../../Models/Requests/SaveLevers";
import { ActionTypes } from "../../../../Redux/actionsTypes";
import { IGeneralState } from "../../../../Redux/reducers/IGeneralState";
import { inputFormatter, inputParser } from "../../../../Utils/helperFunctions";
import AsupBarChart_Ant from "../../../Charts/AsupBarChart/AsupBarChart_Ant";
import CommentsMng from "../CommentsMng";
import parse from "html-react-parser";
import SubQuestionTableReadonly from "./SubQuestionTableReadonly";
import { t } from "i18next";
import SubQuestionTableCostOfDelays from "./SubQuestionTableCostOfDelays";
import SubQuestionTableRates from "./SubQuestionTableRates";
import SubQuestionTableSales from "./SubQuestionTableSales";
const { Text } = Typography;
interface ISubQuestionProps {
  question: ILeverQuestion;
  onchangeSubquestionInput: (
    value: any,
    questionV: ILeverQuestion,
    cell: ILeverCell
  ) => any;
  onchangeSubquestionTable: (questionV: ILeverQuestion) => any;
  isCustomer: boolean;
  addQuestion: (questionId: number) => any;
  selectedQuestions: number[];
  saveLevers: () => any;
}

function SubQuestionSection(props: ISubQuestionProps) {
  const [question, setQuestion] = useState(props.question);
  const [explanationVisible, setExplanationVisible] = useState(false);
  const [targetVisible, setTargetVisible] = useState(false);
  useEffect(() => {
    console.log("updated question");
    setQuestion(props.question);
  }, [props.question]);
  const showDrawer = () => {
    setExplanationVisible(true);
  };

  const onClose = () => {
    setExplanationVisible(false);
  };

  const showTargetDrawer = () => {
    setTargetVisible(true);
  };

  const onTargetClose = () => {
    setTargetVisible(false);
  };

  // if (question.subquestions?.isTable) {
  //     console.log("question.subquestions?.isTable", question.code, question.kpiValue.value);
  // }
  return (
    <div
      className="question_render_div"
      key={`question_render_div__${question.id}`}
    >
      <Space direction="horizontal" key={`question_space__${question.id}`}>
        {!props.isCustomer && question.showToCustomer == true ? (
          <Tooltip title={`${t("Check to manage assignment")}`} placement="top">
            <Checkbox
              style={{ marginLeft: "2px" }}
              checked={props.selectedQuestions.includes(question.id)}
              onChange={(e) => {
                props.addQuestion(question.id);
              }}
            />
          </Tooltip>
        ) : null}

        <Tooltip title={`${t("Explanation")}`} placement="top">
          <InfoCircleOutlined style={{ fontSize: 20 }} onClick={showDrawer} />
        </Tooltip>
        <Tooltip title={`${t("Goal")}`} placement="top">
          <QuestionCircleOutlined
            style={{ fontSize: 20 }}
            onClick={showTargetDrawer}
          />
        </Tooltip>
        {props.isCustomer ? null : (
          <CommentsMng
            question={{ ...question }}
            onchangeSubquestionInput={props.onchangeSubquestionInput.bind(this)}
          />
        )}
        {props.isCustomer ? null : (
          <Popover
            getPopupContainer={() => document.getElementById("app-layout")}
            placement="top"
            key={`popover_kpivalue_${question.code}`}
            content={
              <AsupBarChart_Ant
                leverNumber={question.code}
                width={600}
                height={400}
                showChartDetails={true}
                showChartControls={false}
              />
            }
            trigger="click"
          >
            <Tooltip title={`${t("Benchmarking Chart")}`} placement="bottom">
              <BarChartOutlined style={{ fontSize: 20 }} />
            </Tooltip>
          </Popover>
        )}
      </Space>
      <Drawer
        getContainer={() => document.getElementById("app-layout")}
        key={`drawer_explanation_question_${question.id}`}
        title={`${question.text}`}
        placement="right"
        onClose={onClose}
        open={explanationVisible}
      >
        <Paragraph copyable>{parse(question.explanation ?? "")}</Paragraph>
      </Drawer>
      <Drawer
        getContainer={() => document.getElementById("app-layout")}
        key={`drawer_ziel_question_${question.id}`}
        title={`${question.text}`}
        placement="right"
        onClose={onTargetClose}
        open={targetVisible}
      >
        <Paragraph copyable>{parse(question.target ?? "")}</Paragraph>
      </Drawer>
      <Text strong>{`${question.text}`}</Text>
      {question.subquestions?.isTable ? (
        question.subquestions.isReadonly ? (
          <SubQuestionTableReadonly
            question={props.question}
            subquestions={props.question.subquestions}
          />
        ) : question.subquestions.columnsNumber == 4 ? (
          <SubQuestionTableCostOfDelays
            saveLevers={props.saveLevers}
            question={question}
            onchangeSubquestionTable={props.onchangeSubquestionTable}
          />
        ) : question.subquestions.columnsNumber == 2 ? (
          <SubQuestionTableRates
            saveLevers={props.saveLevers}
            question={question}
            onchangeSubquestionTable={props.onchangeSubquestionTable}
          />
        ) : (
          <SubQuestionTableSales
            saveLevers={props.saveLevers}
            question={question}
            onchangeSubquestionTable={props.onchangeSubquestionTable}
          />
        )
      ) : props.isCustomer ? (
        SubQuestionSectionForCustomer(question, props.onchangeSubquestionInput)
      ) : (
        SubQuestionSectionTemp(question, props.onchangeSubquestionInput)
      )}
    </div>
  );
}
const mapStateToProps = (state: IGeneralState) => {
  return {
    selectedQuestions: state.leverTool.selectedQuestions,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addQuestion: (questionId: number) =>
      dispatch({
        type: ActionTypes.ChangeSelectedQuestions,
        questionId: questionId,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubQuestionSection);

export function SubQuestionSectionForCustomer(
  question: ILeverQuestion,
  onchangeSubquestionInput: any
) {
  return (
    <div
      className="sub-question-div"
      key={`subquestion_temp_div_${question.id}`}
    >
      {question.subquestions?.fields?.map((fieldrow, fi) => {
        var countOfShownFieldsInRow = fieldrow.filter(
          (c) => c.showToCustomer == true
        )?.length;
        if (countOfShownFieldsInRow === 0) return null;
        return (
          <Row
            className="sub-question-row"
            gutter={10}
            key={`fieldrow_${question.code}_${fi}`}
          >
            {fieldrow.map((field) => {
              if (field.showToCustomer != true) return <></>;
              if (field.isEditable == true) {
                return (
                  <Col
                    className="subquestion-input"
                    span={24 / question.subquestions.columnsNumber}
                  >
                    {field.unit == "%" ? (
                      <InputNumber                      
                        min={0}
                        max={100}
                        key={`input_field_${question.code}_${field.code}`}
                        addonAfter={field.unit ? <>{field.unit}</> : null}
                        name={`${question.code}_${field.code}`}
                        formatter={(value) =>
                          `${inputFormatter(value, i18n.language)}`
                        }
                        parser={(x) => inputParser(x, i18n.language)}
                        value={field.value}
                        onChange={(e) => {
                          //console.log("ONCHANGE ", e, question.code);
                          onchangeSubquestionInput(e, question, field);
                        }}
                      />
                    ) : (
                      <InputNumber
                        key={`input_field_${question.code}_${field.code}`} 
                        addonAfter={field.unit ? <>{field.unit}</> : null}
                        name={`${question.code}_${field.code}`}
                        formatter={(value) =>
                          `${inputFormatter(value, i18n.language)}`
                        }
                        parser={(x) => inputParser(x, i18n.language)}
                        value={field.value}
                        onChange={(e) => {
                          //console.log("ONCHANGE ", e, question.code);
                          onchangeSubquestionInput(e, question, field);
                        }}
                      />
                    )}
                  </Col>
                );
              } else {
                if (field.code == "") {
                  return (
                    <Col
                      className="subquestion-label"
                      span={24 / question.subquestions.columnsNumber}
                    >
                      {field.displayname}
                    </Col>
                  );
                }
              }
            })}
          </Row>
        );
      })}
    </div>
  );
}

export function SubQuestionSectionTemp(
  question: ILeverQuestion,
  onchangeSubquestionInput: any
) {
  return (
    <div
      className="sub-question-div"
      key={`subquestion_temp_div_${question.id}`}
    >
      {question.subquestions?.fields?.map((fieldrow, fi) => {
        return (
          <Row
            className="sub-question-row"
            gutter={10}
            key={`fieldrow_${question.code}_${fi}`}
          >
            {fieldrow.map((field) => {
              if (field.isEditable == true) {
                return (
                  <Col
                    className="subquestion-input"
                    span={24 / question.subquestions.columnsNumber}
                  >
                    {field.unit == "%" ? (
                      <InputNumber
                        min={0}
                        max={100}
                        key={`input_field_${question.code}_${field.code}`}
                        addonAfter={field.unit ? <>{field.unit}</> : null}
                        name={`${question.code}_${field.code}`}
                        formatter={(value) =>
                          `${inputFormatter(value, i18n.language)}`
                        }
                        parser={(x) => inputParser(x, i18n.language)}
                        value={field.value}
                        onChange={(e) => {
                          //console.log("ONCHANGE ", e, question.code);
                          onchangeSubquestionInput(e, question, field);
                        }}
                      />
                    ) : (
                      <InputNumber
                        key={`input_field_${question.code}_${field.code}`}
                        style={{
                          border: field.code === 'Number_Of_RD_Employees' ? '2px solid red' : ''
                      }}
                        addonAfter={field.unit ? <>{field.unit}</> : null}
                        name={`${question.code}_${field.code}`}
                        formatter={(value) =>
                          `${inputFormatter(value, i18n.language)}`
                        }
                        parser={(x) => inputParser(x, i18n.language)}
                        value={field.value}
                        onChange={(e) => {
                          //console.log("ONCHANGE ", e, question.code);
                          onchangeSubquestionInput(e, question, field);
                        }}
                      />
                    )}
                  </Col>
                );
              } else {
                if (field.code == "") {
                  return (
                    <Col
                      className="subquestion-label"
                      span={24 / question.subquestions.columnsNumber}
                    >
                      {field.displayname}
                    </Col>
                  );
                } else {
                  return (
                    <Col
                      className="subquestion-result"
                      span={24 / question.subquestions.columnsNumber}
                    >
                      <InputNumber
                        key={`input_field_${question.code}_${field.code}`}
                        addonAfter={field.unit ? <>{field.unit}</> : null}
                        disabled
                        name={`${question.code}_${field.code}`}
                        formatter={(value) =>
                          `${inputFormatter(value, i18n.language)}`
                        }
                        parser={(x) => inputParser(x, i18n.language)}
                        value={field.value}
                        onChange={(e) => {
                          //console.log("ONCHANGE ", e, question.code);
                          onchangeSubquestionInput(e, question, field);
                        }}
                      />
                    </Col>
                  );
                }
              }
            })}
          </Row>
        );
      })}
    </div>
  );
}

export function SubQuestionSectionViewTemp(
  question: ILeverQuestion,
  subquestions: ILeverSubquestion
) {
  return (
    <div
      className="sub-question-div"
      key={`subquestion_viewtemp_${question.id}`}
    >
      {subquestions?.fields?.map((fieldrow, fi) => {
        var countOfShownFieldsInRow = fieldrow.filter(
          (c) => c.showToCustomer == true
        )?.length;
        if (countOfShownFieldsInRow === 0) return null;
        return (
          <Row
            className="sub-question-row"
            gutter={10}
            key={`fieldrow_${question.code}_${fi}`}
          >
            {fieldrow.map((field) => {
              if (field.showToCustomer != true) return <></>;
              if (field.isEditable == true) {
                return (
                  <Col
                    className="subquestion-input"
                    span={24 / subquestions.columnsNumber}
                  >
                    <InputNumber
                      key={`input_field_${question.code}_${field.code}`}
                      addonAfter={field.unit ? <>{field.unit}</> : null}
                      name={`${question.code}_${field.code}`}
                      formatter={(value) =>
                        `${inputFormatter(value, i18n.language)}`
                      }
                      parser={(x) => inputParser(x, i18n.language)}
                      value={field.value}
                      readOnly
                    />
                  </Col>
                );
              } else {
                if (field.code == "") {
                  return (
                    <Col
                      className="subquestion-label"
                      span={24 / subquestions.columnsNumber}
                    >
                      {field.displayname}
                    </Col>
                  );
                } else {
                  return (
                    <Col
                      className="subquestion-result"
                      span={24 / subquestions.columnsNumber}
                    >
                      <InputNumber
                        key={`input_field_${question.code}_${field.code}`}
                        addonAfter={field.unit ? <>{field.unit}</> : null}
                        disabled
                        name={`${question.code}_${field.code}`}
                        formatter={(value) =>
                          `${inputFormatter(value, i18n.language)}`
                        }
                        parser={(x) => inputParser(x, i18n.language)}
                        value={field.value}
                      />
                    </Col>
                  );
                }
              }
            })}
          </Row>
        );
      })}
    </div>
  );
}
