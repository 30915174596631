import { Button, Col, Divider, Form, Input, InputNumber, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { t } from 'i18next';
import { ICommonResponse } from '../../../Models/Responses/ICommonResponse';
import ModalAdmin from '../../../Components/ModalAdmin/ModalAdmins';
import { IBMProjectItem, IBMProjectItemNew } from '../../../Models/IBMProjectItem';
import { RulesName, RulesRequire } from '../Rules/RulesValidation';
import { IListItem } from '../../../Models/Responses/ProfileResponse';
import i18n from '../../../i18n';
import { inputFormatter, inputParser } from '../../../Utils/helperFunctions';
import { ActionTypes } from '../../../Redux/actionsTypes';
import { IIndustryAll } from '../../../Models/IIndustry';
interface IProps {
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    customers: IListItem[];
    industries: IListItem[];
    isLoading: boolean;
    languagesList: IListItem[];
    currencyList: IListItem[];
    customerId: number;
    bmProjectData: IBMProjectItem;
    updateBmProject: (model: IBMProjectItemNew, customerId: number) => any;
    industriesData: IIndustryAll[];
    segments: IListItem[];
}
const BmProjectEditModal = (props: IProps) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isChangeIndustryId, setIsChangeIndustryId] = useState(false);
    const [segmentData, setSegmentData] = useState<IListItem[]>();
    const [segmentValueId, setSegmentValueId] = useState(props.bmProjectData?.industrySegmentId);
    const [industryValueId, setIndustryValueId] = useState(null);

    const [form] = Form.useForm();
    useEffect(() => {
        if (industryValueId && isChangeIndustryId) {
            let temp = props.industriesData?.filter(i => i.id === industryValueId).map(a => a.industrySegmentResultDto)[0]
            .map(i => { return { value: i.id, label: i18n.language === 'de' ? i.codeDe : i.codeEn } });
            temp.push({value: null, label: "------None------"})
            setSegmentData(temp);
            setSegmentValueId(segmentData[0]?.value);
        } else {
            let temp = props.segments;
            temp.push({value: null, label: "------None------"})
            setSegmentData(temp);
            setSegmentValueId(props.bmProjectData?.industrySegmentId);
        }
        if (!segmentData) {
            return;
        }
    }, [isChangeIndustryId, industryValueId]);

    const formValuesHandler = async () => {
        try {
            await form.validateFields();
            let values = await form.getFieldsValue();
            const uploadBmProject: IBMProjectItemNew = {
                id: props.bmProjectData?.id,
                customerId: props.customerId ? props.customerId : values?.customerId,
                industrySegmentId: segmentValueId,
                industryId: values?.industryId,
                name: values?.name?.trimStart(),
                shortName: values?.shortName,
                locations: values?.locations?.trimStart(),
                currencyId: values?.currencyId,
                defaultLanguageId: values?.defaultLanguageId,
                ebitPercentageN: values?.ebitPercentageN,
                ebitPercentageN1: values?.ebitPercentageN1,
                ebitPercentageN2: values?.ebitPercentageN2,
                salesN: values?.salesN,
                salesN1: values?.salesN1,
                salesN2: values?.salesN2,
                rndEmployees: values?.rndEmployees,
                yearOfExecution: values?.yearOfExecution,
                yearUnderReview: values?.yearUnderReview,
                //innovationRateHard: values?.innovationRateHard,
                internalEighterNumbers: values?.internalEighterNumbers?.trimStart(),
            }
            console.log(uploadBmProject);
            await props.updateBmProject(uploadBmProject, props.customerId);


        } catch (error) {
            console.log(error);
        }
    }
    const closeAndReset = () => {
        setIsModalVisible(false);
        form.resetFields();
        setSegmentData(props.segments as IListItem[]);
        setSegmentValueId(props.bmProjectData?.industrySegmentId)
    }

    return (
        <ModalAdmin title={`${t("Edit")}  ${t("Bm-project")}`}
            btnText={`${t("Edit")}`}
            width="1000px"
            modalResponse={props.modalResponse}
            closeAndReset={closeAndReset}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            footerchildren={[
                <Button key="back" onClick={closeAndReset}>
                    {`${t("Cancel")}`}
                </Button>,
                <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                    {`${t("Save")}`} & {`${t("Send")}`}
                </Button>
            ]}>
            <Form layout="vertical" form={form} initialValues={props.bmProjectData}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="Name" name='name'
                            rules={RulesName({ name: "Name", countChar: 128 })}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item label={`${t("Short Name")}`} name='shortName'
                            rules={[
                                {
                                    required: true,
                                    message: `${t("Short name is required*")}`
                                },
                                {
                                    max: 3,
                                    message: `${t("Short name must be three characters only")}`
                                }, {
                                    whitespace: true,
                                    message: `${t("Short name cannot be empty")}`
                                }

                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item label={`${t("Locations")}`} name='locations'
                        // rules={
                        //     [{ whitespace: true, message: `${t("Locations")} ${t("must not be empty")} ` }, {
                        //         max: 64, message: `${t("Locations")} ${t("must be less than 64 characters.")}`
                        //     }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        {props.customerId ? (null) : (
                            <Form.Item label={`${t("Customer")}`} name='customerId' rules={RulesRequire({ filedName: `${t("Customer")}` })} >
                                <Select
                                    showSearch
                                    placeholder={`${t("Select Customer")}`}
                                    optionFilterProp="children"
                                    getPopupContainer={() => document.getElementById("app-layout")}
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    options={props.customers}
                                />
                            </Form.Item>
                        )}
                        <Form.Item label={`${t("Industry")}`} name="industryId" rules={RulesRequire({ filedName: 'Industry' })} >
                            <Select
                                onChange={(value) => {
                                    let temp = props.industriesData?.filter(i => i.id === value).map(a => a.industrySegmentResultDto)[0]
                                    .map(i => { return { value: i.id, label: i18n.language === 'de' ? i.codeDe : i.codeEn } });
                                    temp.push({value: null, label: "----None---"});
                                    setSegmentData(temp);
                                    setIsChangeIndustryId(true);
                                    setIndustryValueId(value);

                                }
                                }
                                optionFilterProp="children"
                                getPopupContainer={() => document.getElementById("app-layout")}
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                showSearch
                                placeholder={`${t("Select Industry")}`}
                                options={props.industries}
                            />
                        </Form.Item>
                        <Form.Item label={`${t("Industry")} ${t("Segments")}`}  >
                            <Select
                                value={segmentValueId ?? props.bmProjectData?.industrySegmentId}
                                onChange={(value: number) => setSegmentValueId(value)}
                                showSearch
                                optionFilterProp="children"
                                getPopupContainer={() => document.getElementById("app-layout")}
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                placeholder={`${t("Select Industry Segment")}`}
                                options={(segmentData ?? props.segments)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={`${t("Year under Review")}`} name='yearUnderReview' rules={RulesRequire({ filedName: `${t("Year under Review")}` })} >
                            <InputNumber minLength={4} maxLength={4} style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item name='yearOfExecution' label={`${t("Year of Execution")}`} rules={RulesRequire({ filedName: `${t("Year of Execution")}` })} >
                            <InputNumber minLength={4} maxLength={4} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={`${t("Currency")}`} name='currencyId' rules={RulesRequire({ filedName: `${t("Currency")}` })} >
                            <Select
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                showSearch
                                placeholder={`${t("Select Currency")}`}
                                options={props?.currencyList}
                            />
                        </Form.Item>
                        <Form.Item label={`${t("Language")}`} name='defaultLanguageId' rules={RulesRequire({ filedName: 'Language' })} >
                            <Select
                                optionFilterProp="children"
                                getPopupContainer={() => document.getElementById("app-layout")}
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                showSearch
                                placeholder={`${t("Select Language")}`}
                                options={props?.languagesList}
                            />
                        </Form.Item>

                    </Col>
                </Row>
                <Divider />
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label={`${t("Ebit N")}`} name="ebitPercentageN" rules={RulesRequire({ filedName: `${t("Ebit N")}` })}>
                            <InputNumber min={0}
                                max={100} addonAfter="%"
                                formatter={(value) => inputFormatter(value, i18n.language)}
                                parser={(x) => inputParser(x, i18n.language)}
                            />
                        </Form.Item>
                        <Form.Item label={`${t("Ebit N-1")}`} name="ebitPercentageN1" rules={RulesRequire({ filedName: `${t("Ebit N-1")}` })}>
                            <InputNumber min={0}
                                formatter={(value) => inputFormatter(value, i18n.language)}
                                parser={(x) => inputParser(x, i18n.language)}
                                max={100} addonAfter="%"
                            />
                        </Form.Item>
                        <Form.Item label={`${t("Ebit N-2")}`} name="ebitPercentageN2" rules={RulesRequire({ filedName: `${t("Ebit N-2")}` })} >
                            <InputNumber min={0}
                                formatter={(value) => inputFormatter(value, i18n.language)}
                                parser={(x) => inputParser(x, i18n.language)}
                                max={100} addonAfter="%" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={`${t("Sales")} N`} name="salesN" rules={RulesRequire({ filedName: `${t("Sales")} N` })}>
                            <InputNumber
                                formatter={(value) => inputFormatter(value, i18n.language)}
                                parser={(x) => inputParser(x, i18n.language)} min={0}
                            />
                        </Form.Item>
                        <Form.Item label={`${t("Sales")} N-1`} name="salesN1"
                            rules={RulesRequire({ filedName: `${t("Sales")} N-1` })}
                        >
                            <InputNumber
                                formatter={(value) => inputFormatter(value, i18n.language)}
                                parser={(x) => inputParser(x, i18n.language)} min={0}
                            />
                        </Form.Item>
                        <Form.Item label={`${t("Sales")} N-2`} name="salesN2"
                            rules={RulesRequire({ filedName: `${t("Sales")} N-2` })}
                        >
                            <InputNumber
                                formatter={(value) => inputFormatter(value, i18n.language)}
                                parser={(x) => inputParser(x, i18n.language)} min={0}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={`${t("Number of R&D Employees")}`} name="rndEmployees" >
                            <InputNumber disabled />
                        </Form.Item>
                        <Form.Item label={`${t("Internal eighter numbers")}`} name='internalEighterNumbers' rules={RulesRequire({ filedName: 'Internal Eighter Numbers' })} hasFeedback>
                            <Input />
                        </Form.Item>

                    </Col>
                    <Col span={6}>
                        <Form.Item label={`${t("Innovation Rate (hard)")}`} name="innovationRateHard">
                            <InputNumber min={0}
                                disabled
                                formatter={(value) => inputFormatter(value, i18n.language)}
                                parser={(x) => inputParser(x, i18n.language)}
                                max={100} addonAfter="%" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </ModalAdmin>
    )
}
const mapStateToProps = (state: any) => {
    return {
        industriesData: state.industries.industriesResult,

    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateBmProject: (model: IBMProjectItemNew, customerId: number) => dispatch({ type: ActionTypes.UPDATE_BM_PROJECT, model: model, customerId }),


    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BmProjectEditModal);
