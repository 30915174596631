import { Button, Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select, Space, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { IBMProjectItem, IBMProjectALL, IBMProjectItemNew } from '../../../Models/IBMProjectItem';
import { IIndustryAll } from '../../../Models/IIndustry';
import { ItemCustomerManagement } from '../../../Models/ItemAdminTable/Items';
import { ActionTypes } from '../../../Redux/actionsTypes';
import './index.scss';
import BmProjectTable from './BMProjectTable';
import type { DatePickerProps } from 'antd';
import { ICommonResponse } from '../../../Models/Responses/ICommonResponse';
import ModalAdmin from '../../../Components/ModalAdmin/ModalAdmins';
import { inputFormatter, inputParser } from '../../../Utils/helperFunctions';
import i18n from '../../../i18n';
import { RulesName, RulesRequire } from '../Rules/RulesValidation';
import { IGeneralSettings, IListItem } from '../../../Models/Responses/ProfileResponse';
import { ISegmentAll } from '../../../Models/IIndustrySegment';
import { useSearchParams } from 'react-router-dom';
import { t } from 'i18next';
import de from 'antd/es/date-picker/locale/de_DE';
import en from 'antd/es/date-picker/locale/en_US';

interface IListSegments extends IListItem {
    industryId: number
}
interface IProps {
    getBmProjects: (customerId: number) => any;
    bmProjects: IBMProjectALL[];
    getCustomers: () => any;
    customers: ItemCustomerManagement[];
    getIndustries: () => any;
    industries: IIndustryAll[];
    createBmProject: (model: IBMProjectItemNew, customerId: number) => any;
    isLoading: boolean;
    generalSettings: IGeneralSettings;
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    getIndustrySegments: (id: number) => any;
    industrySegmentsData: ISegmentAll,
}
const BmProject = (props: IProps) => {
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [industryIdValue, setIndustryValueId] = useState(null);
    const [segmentValueId, setSegmentValueId] = useState(null);
    const [formValues, setFormValues] = useState<IBMProjectItemNew>();
    const [searchParams, setSearchParams] = useSearchParams();
    const customerIdUrl = searchParams.get('customerId');
    const customerName = searchParams.get('customerName');
    let customerId: number;
    if (customerIdUrl === null) {
        customerId = null
    } else {
        customerId = Number(searchParams.get('customerId'))
    }
    useEffect(() => {
        if (customerId) {
            props.getBmProjects(customerId)
        } else {
            props.getBmProjects(null);
        }
        props.getCustomers(); props.getIndustries();
    }, [customerId]);
    useEffect(() => {
        if (industryIdValue) {
            props.getIndustrySegments(industryIdValue)
        }
    }
        , [industryIdValue]
    )
    const multiplyEbitWithSale = (ebit: number, sale: number) => {
        if ((ebit === 0 || null) || (sale === 0 || null)) {
            return 0;
        } else {
            return (ebit * sale) / 100;
        }
    }

    const customersData: IListItem[] = props.customers?.map(c => { return { value: c.id, label: c.name } }) ?? [];
    const industriesData: IListItem[] = props.industries?.map(i => { return { value: i.id, label: i18n.language === 'de' ? i.codeDe : i.codeEn } }) ?? [];
    const segmentData: IListSegments[] = props.industrySegmentsData?.list?.map(i => { return { value: i.id, label: i18n.language === 'de' ? i.codeDe : i.codeEn, industryId: props.industrySegmentsData?.industryId } }) ?? [];
    const bmDataRow: IBMProjectItem[] = props.bmProjects?.map(bm => {
        return {
            ...bm,
            key: `bm_${bm?.id}`,
            id: bm?.id,
            customerId: bm?.customer?.id,
            customerName: bm?.customer?.name,
            currencyId: bm?.currency?.id,
            currencyCode: bm.currency?.code,
            ebitAmountN: multiplyEbitWithSale(bm?.ebitPercentageN, bm?.salesN),
            ebitAmountN1: multiplyEbitWithSale(bm?.ebitPercentageN1, bm?.salesN1),
            ebitAmountN2: multiplyEbitWithSale(bm?.ebitPercentageN2, bm?.salesN2),
            defaultLanguageId: bm?.defaultLanguage?.id,
            defaultLanguageCode: bm?.defaultLanguage?.code,
        };
    }) ?? [];

    const formValuesHandler = async () => {
        try {
            await form.validateFields();
            const newBmProject = {
                ...formValues,
                customerId: customerId ?? formValues?.customerId,
                industrySegmentId: segmentValueId ?? segmentData.find(s => s.industryId === industryIdValue)?.value
            }
            await props.createBmProject(newBmProject, customerId ?? null);
        } catch (error) {
            console.log(error);
        }
    }
    const closeAndReset = () => {
        setIsModalVisible(false);
        form.resetFields();
        setIndustryValueId(null);
        setSegmentValueId(null);
        setFormValues({} as IBMProjectItemNew);
    }
    const onchangeYearExecution: DatePickerProps['onChange'] = (date: any, dateString: string) => setFormValues({ ...formValues, yearOfExecution: parseInt(dateString) });
    const onchangeYearReview: DatePickerProps['onChange'] = (date: any, dateString: string) => setFormValues({ ...formValues, yearUnderReview: parseInt(dateString) });
    return (
        <Spin spinning={props.isLoading || props.isModalLoading} >
            <div className="BmProject-component admin-component">
                {customerName && <Typography.Title level={4} style={{ color: "#1644b0" }}>{customerName}</Typography.Title>}
                <Space direction="vertical" size="small" >
                    <ModalAdmin title={`${t("Add")} ${t("New")} ${t("Bm-project")}`} btnText={`${t("Add")}`}
                        width="1000px"
                        modalResponse={props.modalResponse}
                        closeAndReset={closeAndReset}
                        isModalVisible={isModalVisible}
                        setIsModalVisible={setIsModalVisible}
                        footerchildren={[
                            <Button key="back" onClick={closeAndReset}>
                                {`${t("Cancel")}`}
                            </Button>,
                            <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                                {`${t("Save")}`} & {`${t("Send")}`}
                            </Button>
                        ]}>
                        <Form layout="vertical" form={form} >
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item label="Name" name='name'
                                        rules={RulesName({ name: "Name", countChar: 128 })}
                                        hasFeedback>
                                        <Input onChange={(e) => setFormValues({ ...formValues, name: (e.target.value).trimStart() })} />
                                    </Form.Item>
                                    <Form.Item label={`${t("Short Name")}`} name='shortName'
                                        rules={[
                                            {
                                                required: true,
                                                message: `${t("Short name is required*")}`
                                            },
                                            {
                                                max: 3,
                                                message: `${t("Short name must be three characters only")}`
                                            }, {
                                                whitespace: true,
                                                message: `${t("Short name cannot be empty")}`
                                            }

                                        ]}
                                        hasFeedback>
                                        <Input onChange={(e) => setFormValues({ ...formValues, shortName: (e.target.value).trimStart() })} />
                                    </Form.Item>
                                    <Form.Item label={`${t("Locations")}`} name='locations' rules={
                                        [{ whitespace: true, message: `${t("Locations")} ${t("must not be empty")} ` }, {
                                            max: 64, message: `${t("Locations")} ${t("must be less than 64 characters.")}`
                                        }]}>
                                        <Input onChange={(e) => setFormValues({ ...formValues, locations: (e.target.value).trim() })} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    {customerId ? (null) : (
                                        <Form.Item label={`${t("Customer")}`} name='customerId' rules={RulesRequire({ filedName: `${t("Customer")}` })} hasFeedback>
                                            <Select
                                                onChange={(value: any) =>
                                                    setFormValues({ ...formValues, customerId: value })
                                                }
                                                showSearch
                                                placeholder={`${t("Select Customer")}`}
                                                optionFilterProp="children"
                                                getPopupContainer={() => document.getElementById("app-layout")}
                                                filterOption={(input, option) => (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())}
                                                options={customersData}
                                            />
                                        </Form.Item>
                                    )}
                                    <Form.Item label={`${t("Industry")}`} name="industryId" rules={RulesRequire({ filedName: 'Industry' })} hasFeedback>
                                        <Select
                                            onChange={(value: number) => {
                                                setSegmentValueId(null);
                                                setIndustryValueId(value);
                                                setFormValues({ ...formValues, industryId: value })
                                            }}
                                            optionFilterProp="children"
                                            getPopupContainer={() => document.getElementById("app-layout")}
                                            filterOption={(input, option) => (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())}
                                            showSearch
                                            placeholder={`${t("Select Industry")}`}
                                            options={industriesData}
                                        />
                                    </Form.Item>
                                    <Form.Item label={`${t("Industry")} ${t("Segments")}`} >
                                        <Select
                                            value={segmentValueId ? segmentValueId : segmentData?.find(s => s?.industryId === industryIdValue)?.value}
                                            onChange={(value: number) => setSegmentValueId(value)}
                                            showSearch
                                            optionFilterProp="children"
                                            getPopupContainer={() => document.getElementById("app-layout")}
                                            filterOption={(input, option) => (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())}
                                            placeholder={`${t("Select Industry Segment")}`}
                                            options={segmentData}
                                            disabled={!industryIdValue}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label={`${t("Year under Review")}`} name="yearUnderReview" rules={RulesRequire({ filedName: `${t("Year under Review")}` })} hasFeedback>
                                        <DatePicker onChange={onchangeYearReview} picker="year"
                                            locale={i18n.language === 'de' ? de : en} style={{ width: "100%" }} />
                                    </Form.Item>
                                    <Form.Item label={`${t("Year of Execution")}`} name="yearOfExecution" rules={RulesRequire({ filedName: `${t("Year of Execution")}` })} hasFeedback>
                                        <DatePicker onChange={onchangeYearExecution} picker="year"
                                            locale={i18n.language === 'de' ? de : en}
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>


                                </Col>
                                <Col span={6}>
                                    <Form.Item label={`${t("Currency")}`} name='currencyId' rules={RulesRequire({ filedName: `${t("Currency")}` })} hasFeedback>
                                        <Select
                                            onChange={(value: number) =>
                                                setFormValues({ ...formValues, currencyId: value })
                                            }
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())}
                                            showSearch
                                            placeholder={`${t("Select Currency")}`}
                                            options={props.generalSettings?.curruncyList}
                                        />
                                    </Form.Item>
                                    <Form.Item label={`${t("Language")}`} name='defaultLanguageId' rules={RulesRequire({ filedName: 'Language' })} hasFeedback>
                                        <Select
                                            onChange={(value: number) =>
                                                setFormValues({ ...formValues, defaultLanguageId: value })
                                            }
                                            optionFilterProp="children"
                                            getPopupContainer={() => document.getElementById("app-layout")}
                                            filterOption={(input, option) => (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())}
                                            showSearch
                                            placeholder={`${t("Select Language")}`}
                                            options={props.generalSettings?.languageList}
                                        />
                                    </Form.Item>

                                </Col>
                            </Row>
                            <Divider />
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item label="Ebit N" name="ebitPercentageN" rules={RulesRequire({ filedName: 'Ebit N' })}>
                                        <InputNumber onChange={(value: number) => setFormValues({ ...formValues, ebitPercentageN: value })} min={0}
                                            max={100} addonAfter="%"
                                            formatter={(value) => inputFormatter(value, i18n.language)}
                                            parser={(x) => inputParser(x, i18n.language)}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Ebit N-1" name="ebitPercentageN1" rules={RulesRequire({ filedName: 'Ebit N-1' })}>
                                        <InputNumber onChange={(value: number) => setFormValues({ ...formValues, ebitPercentageN1: value })} min={0}
                                            formatter={(value) => inputFormatter(value, i18n.language)}
                                            parser={(x) => inputParser(x, i18n.language)}
                                            max={100} addonAfter="%"
                                        />
                                    </Form.Item>
                                    <Form.Item label="Ebit N-2" name="ebitPercentageN2" rules={RulesRequire({ filedName: 'Ebit N-2' })} >
                                        <InputNumber onChange={(value: number) => setFormValues({ ...formValues, ebitPercentageN2: value })} min={0}
                                            formatter={(value) => inputFormatter(value, i18n.language)}
                                            parser={(x) => inputParser(x, i18n.language)}
                                            max={100} addonAfter="%" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label={`${t("Sales")} N`} name="salesN" rules={RulesRequire({ filedName: `${t("Sales")} N` })}>
                                        <InputNumber onChange={(value: number) => setFormValues({ ...formValues, salesN: value })}
                                            formatter={(value) => inputFormatter(value, i18n.language)}
                                            parser={(x) => inputParser(x, i18n.language)} min={0}
                                        />
                                    </Form.Item>
                                    <Form.Item label={`${t("Sales")} N-1`} name="salesN1"
                                        rules={RulesRequire({ filedName: `${t("Sales")} N-1` })}
                                    >
                                        <InputNumber onChange={(value: number) => setFormValues({ ...formValues, salesN1: value })}
                                            formatter={(value) => inputFormatter(value, i18n.language)}
                                            parser={(x) => inputParser(x, i18n.language)} min={0}
                                        />
                                    </Form.Item>
                                    <Form.Item label={`${t("Sales")} N-2`} name="salesN2"
                                        rules={RulesRequire({ filedName: `${t("Sales")} N-2` })}
                                    >
                                        <InputNumber onChange={(value: number) => setFormValues({ ...formValues, salesN2: value })}
                                            formatter={(value) => inputFormatter(value, i18n.language)}
                                            parser={(x) => inputParser(x, i18n.language)} min={0}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    {/* <Form.Item label={`${t("Number of R&D Employees")}`} name="rndEmployees" >
                                        <InputNumber onChange={(value: number) => setFormValues({ ...formValues, rndEmployees: value })} min={0} />
                                    </Form.Item> */}
                                    <Form.Item label={`${t("Internal eighter numbers")}`} name='internalEighterNumbers' rules={RulesRequire({ filedName: 'Internal Eighter Numbers' })} hasFeedback>
                                        <Input onChange={(e) => setFormValues({ ...formValues, internalEighterNumbers: e.target.value })} />
                                    </Form.Item>
                                </Col>
                               {/*  <Col span={6}>
                                    <Form.Item label={`${t("Innovation Rate (hard)")}`} name="innovationRateHard">
                                        <InputNumber
                                            readOnly
                                            formatter={(value) => inputFormatter(value, i18n.language)}
                                            parser={(x) => inputParser(x, i18n.language)}
                                            addonAfter="%" />
                                    </Form.Item>
                                </Col> */}
                            </Row>
                        </Form>
                    </ModalAdmin>
                    <BmProjectTable
                        key="BmProject_Table"
                        originDataRow={bmDataRow}
                        customers={customersData}
                        industries={industriesData}
                        languagesList={props.generalSettings?.languageList}
                        currencyList={props.generalSettings?.curruncyList}
                        customerId={customerId}
                    />
                </Space>
            </div >
        </Spin >
    )
}
const mapStateToProps = (state: any) => {
    return {
        bmProjects: state.bmProjects.bmProjectsResult,
        customers: state.customerReducer.responseResult,
        industries: state.industries.industriesResult,
        industrySegmentsData: state.industrySegments.industrySegmentsResult,
        isLoading: state.bmProjects.isBmProjectLoading,
        generalSettings: state.generalInfo?.profileResponse?.generalSettings,
        isModalLoading: state.bmProjects.isModalLoading,
        modalResponse: state.bmProjects.modalResponse,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getCustomers: () => dispatch({ type: ActionTypes.GET_CUSTOMERS }),
        getBmProjects: (customerId: number) => dispatch({ type: ActionTypes.GET_BM_PROJECTS, customerId }),
        getIndustries: () => dispatch({ type: ActionTypes.GET_INDUSTRIES }),
        createBmProject: (model: IBMProjectItemNew, customerId) => dispatch({ type: ActionTypes.CREATE_BM_PROJECT, model: model, customerId }),
        getIndustrySegments: (id: number) => dispatch({ type: ActionTypes.GET_INDUSTRYSEGMENTS, industryId: id }),

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(BmProject);
